
import { API } from "../../api-config"
import SecureLS from "secure-ls"

export const signIn = async (email, password) => {

  return await fetch(`${API}/auth/signin`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  })
    .then((response) => {
      return response
    })
    .catch((err) => {

      return err
    })
}


export const signUp = async (userData) => {
  return await fetch(`${API}/auth/signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  })
    .then((response) => {
      return response
    })
    .catch((err) => {

      return err
    })
}


export const resetPassword = async (email) => {
  return await fetch(`${API}/auth/reset-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  })
    .then((response) => {
      return response
    })
    .catch((err) => {

      return err
    })
}


export const sendPhoneOtp = async ({ phone }) => {
  const ls = new SecureLS({ encodingType: "aes" })
  const token = ls.get("token")



  return await fetch(`${API}/auth/phone/send/otp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({ phone: phone }),
  })
    .then(async (response) => {

      if (response?.status !== 200) {
        let error = await response.json()
        throw Error(`${error.error}`)
      }

      return response.json()
    })
    .catch((err) => {

      throw err
    })
}


export const updatePhone = async ({ phone }) => {
  const ls = new SecureLS({ encodingType: "aes" })
  const token = ls.get("token")
  const userId = ls.get('userId')



  return await fetch(`${API}/user/${userId}
/updatePhone`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({ phone: phone }),
  })
    .then(async (response) => {

      if (response?.status !== 200) {
        let error = await response.json()
        throw Error(`${error.error}`)
      }

      return response.json()
    })
    .catch((err) => {

      throw err
    })
}




export const verifyPhoneOtpAndUpdate = async ({ phone, otp }) => {
  const ls = new SecureLS({ encodingType: "aes" })
  const token = ls.get("token")
  const userId = ls.get("userId")


  return await fetch(`${API}/auth/phone/verify/otp/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({ phone: phone, otp: otp, userId: userId }),
  })
    .then(async (response) => {
      if (response?.status !== 200) {
        let error = await response.json()
        throw Error(`${error.error}`)
      }

      return response.json()
    })
    .catch((err) => {

      throw err
    })
}
